.head-container {
    background-image: url('../../Assets/Images/landing-christmas-1920x1080.jpg');
    background-size: 50%;
    background-position-x: right;
    background-position-y: center;
    background-repeat: no-repeat;
    background-color: #07403a;
    color: #fff;
    display: grid;
    grid-template-columns: 50% auto 8px;
}

.title,
.title a,
.title a:hover,
.title a:visited,
.title a:active {
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    height: 250px;
    width: auto;
    display: flex;
    align-items: flex-end;
    padding-left: 15px;
    grid-column: 1 / 2;
    color: inherit;
    text-decoration: none;
}

.popup-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: column;
    grid-column: 2 / 3;
}

.popup-container {
    display: flex;
    justify-content: flex-end;
}

.fill {
    grid-column: 3 / 4;
}

h1 {
    font-size: 4.5em;
    line-height: 70px;
}

.graphic {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom: -2px;
    margin-right: 10%;
}

.box {
    display: flex;
    justify-content: left;
    flex-direction: column;
    background-color: #F53948;
    color: #fff;
    height: 175px;
    width: 300px;
    padding: 10px 15px;
    border-radius: 7px;
    position: relative;
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
    font-size: 0.85em;
}

.box p {
    margin-top: 10px;
}

.exit {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    right: 15px;
    width: 25px;
    height: 25px;
    border-radius: 30px;
    background-color: rgba(255,255,255,0.2);
    cursor: pointer;
    font-size: 0.8em;
    font-family: Verdana, Geneva, Tahoma, sans-serif
}

.arrow-down {
    height: 0;
    width: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 30px solid #F53948;
}

.toggle {
    position: absolute; 
    z-index: 2;
    color: #F53948;
    cursor: pointer;

    top: 260px;
    right: 10.4%;
}

.toggle span {
    border-radius: 3px;
    border: 1px solid #07403a;
    background-color: #F53948;
    color: #fff;
    padding: 0.2rem;
  
    /* Needs prefixing */
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
}

.toggle a,
.toggle a:hover,
.toggle a:visited,
.toggle a:active {
    text-decoration: none;
}

.toggle span:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

@media (max-width: 1000px) {
    .popup-wrapper {
        visibility: hidden;
    }

    .toggle {
        top: 10px;
        bottom: auto;
        right: 0;
        left: 10px;
    }

    .toggle span {
        background-color: #e54451;
        color: white;
    }
}

