.form-container {
    height: 100%;
    padding: 1rem 1rem 0 1rem;
    margin: 0 0 0.5rem 0;
    background-color: #c4f3ee;
    border-radius: 3px;
  
    /* Needs prefixing */
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
}

label, 
input {
    display: inline-block;
}

label {
    margin-left: -5px;
}

.email {
    margin-left: 10px;
    margin-top: -5px;
}