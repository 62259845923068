.content {
    border: none;
    padding-top: 10px;
    padding-left: 15px;
    height: auto;
    width: 40%;
}

.participants {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.participants span {
    display:inline-block;
    width: 200px;
    word-break: break-all;
    text-align:left;
}
.participants span:first-of-type {
    text-align:right
}

.content ul {
    list-style: none;
    font-size: 1.6em;
}

.content ul li {
    height: 40%;
    padding: 0.2rem 0 0 0.2rem;
    margin: 0 0 0.5rem 0;
    background-color: #c4f3ee;
    border-radius: 3px;
  
    /* Needs prefixing */
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
}

input[type="text"]:focus {
    background: #e54451;
}

#key-input {
    margin-top: 7px;
}

::placeholder {
    color: #fff;
}

.game-entry {
    border: none;
    padding-left: 15px;
    height: 100vh;
    display: inline-block;
}
