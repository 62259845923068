@import url('https://fonts.googleapis.com/css?family=Quicksand:300,500&display=swap');

* {
	margin: 0;
	padding: 0;
}

body {
	background-color: #07403a;
	color: #5a5a5a;
	margin: 0;
	padding: 0;
	font-family: "Quicksand", sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
	display: grid;
	grid-template-columns: 10% 80% 10%;
}

.leftblock {
	grid-column: 1 / 2;
}

.center {
	background-color: #fff;
	min-height: 95vh;
	grid-column: 2 / 3;
	margin: 0;
	border-radius: 3px;
}

.rightblock {
	grid-column: 3;
}

.foreground {
	position: fixed;
	z-index: 5;
	height: 100vh;
	width: 100%;
	background: none;
	background-color: rgba(255, 255, 255, 0);
	pointer-events: none;
}

#game {
	visibility: visible;
}

#key {
	visibility: hidden;
}

@media (max-width: 1000px) {
	.container {
		grid-template-columns: 0 100% 0;
	}

	.leftblock {
		grid-column: unset;
	}

	.rightblock {
		grid-column: unset;
	}
}