input[type="email"],
input[type="text"],
input[type="date"] {
    box-sizing: border-box; 
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box; 
    background-color: #F53948;
    border: 1px solid #07403a;
    border-radius: 3px;
    color:#fff;
    padding: 12px 10px;
    margin-bottom: 20px;
    margin-right: 10px;
    outline: none;
    cursor: pointer;
}

input[type="email"],
input[type="text"] {
    height: 2em;
    width: 400px;
}

input[type="date"] {
    height: 2em;
    width: 324px;
    margin-top: 7px;
    margin-bottom: 4px;
}

input[type="text"]:focus {
    background: #e54451;
}

::placeholder {
    color: #fff;
}

button[type="submit"] {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    height: 4.0em;
    width: 325px;
    background-color: #07403a;
    border: 1px solid #07403a;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 0.4rem;
}

button[type="reset"] {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    height: 2em;
    width: 325px;
    background-color: #07403a;
    border: 1px solid #07403a;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 0.4rem;
    margin-bottom: 20px;
}

button:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.intro {
    border: none;
    padding-top: 10px;
    padding-left: 15px;
    height: auto;
    display: inline-block;
}

.intro input[type="text"] {
    height: 2em;
    width: 324px;
    box-sizing: border-box; 
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box; 
    background-color: #F53948;
    border: 1.5px solid #07403a;
    border-radius: 3px;
    color:#fff;
    padding: 12px 10px;
    margin: 7px 1px;
    outline: none;
}

.intro input[type="text"]:focus {
    background: #e54451;
}

.parts {
    border: none;
    padding-top: 10px;
    padding-left: 15px;
    height: 100vh;
    display: inline-block;
}

.parts ul:first-child {
    margin-top: 0;
}

.parts ul {
    list-style-type: none;
}

.form-bottom {
    margin-bottom: 20px;
}

.form-text {
    padding-bottom: 10px;
}

.post-confirm {
    padding-top: 10px;
    padding-left: 15px;
    height: auto;
    width: 40%;
}


